import React, { Fragment } from "react";
import { Link } from "gatsby";
import { PropTypes } from "prop-types";
import twitter from "../../img/social/twitter.svg";
import discord from "../../img/social/discord.svg";
import envelope from "../../img/social/envelope.svg";

const TruncatedNavbar = class extends React.Component {
  render() {
    return (
      <nav>
        <div className="flex justify-between py-6 mb-8 px-2">
          <div className="block w-full justify-between p-4">
            <div className="block sm:flex justify-between w-full">
              <div>
                <Link
                  to="/"
                  className="flex space-x-4 justify-center"
                  title="Logo"
                >
                  <div className="space-y-0">
                    <div className="flex">
                      <img
                        className="h-auto w-20 justify-center"
                        src="/img/logo.png"
                        alt="palisade"
                      />
                      <img
                        className="h-20 w-auto justify-center"
                        src="/img/palisade_title.png"
                        alt="palisade"
                      />
                    </div>
                    <img
                      className="h-10 justify-center"
                      src="/img/palisade_subtitle.png"
                      alt="palisade"
                    />
                  </div>
                </Link>
                {/* Social media links */}
                <div className="flex w-1/2 mx-auto justify-between mt-2 items-center align-middle">
                  <div className="w-5 pt-1 li-footer">
                    <a title="twitter" href="https://twitter.com/palisadebot">
                      <img src={twitter} alt="Twitter" className="img-social" />
                    </a>
                  </div>
                  <div className="w-5 pt-1 li-footer">
                    <a title="discord" href="https://discord.gg/ledgart">
                      <img src={discord} alt="Discord" className="img-social" />
                    </a>
                  </div>
                  <div className="w-5 pt-1 li-footer align-middle items-center">
                    <Link
                      className="text-base text-palisade-yellow hover:text-white font-black align-middle items-center"
                      to="/contact-us"
                    >
                      <img
                        src={envelope}
                        alt="Discord"
                        className="img-social"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex mt-10 sm:mt-0 justify-end align-center items-center">
                <button
                  className="container w-100 justify-center py-3 px-4 text-xl font-semibold border border-transparent rounded-md text-palisade-grey bg-palisade-yellow hover:text-palisade-yellow hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-palisade-yellow"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://discord.com/api/oauth2/authorize?client_id=921581219240628254&permissions=268446774&scope=bot";
                  }}
                >
                  Add palisade
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default TruncatedNavbar;
