import React from "react";
import { Link } from "gatsby";
import twitter from "../../img/social/twitter.svg";
import discord from "../../img/social/discord.svg";
import envelope from "../../img/social/envelope.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="flex py-6 mt-8 px-2 w-full">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="grid grid-flow-row auto-rows-auto gap-4 lg:flex"></div>
          <div className="flex justify-end items-right px-8">
            <ul>
              <li className="w-5 h-auto pt-1 py-2 li-footer">
                <a title="twitter" href="https://twitter.com/palisadebot">
                  <img src={twitter} alt="Twitter" className="img-social" />
                </a>
              </li>
              <li className="w-5 h-auto pt-1 py-2 li-footer">
                <a
                  title="discord"
                  href="https://discord.com/api/oauth2/authorize?client_id=921581219240628254&permissions=268446774&scope=bot"
                >
                  <img src={discord} alt="Discord" className="img-social" />
                </a>
              </li>
              <li className="w-5 h-auto pt-1 py-2 li-footer">
                <Link
                  className="text-base text-palisade-yellow hover:text-white font-black"
                  to="/contact-us"
                >
                  <img src={envelope} alt="Discord" className="img-social" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
